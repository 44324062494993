import React, { useEffect } from 'react';
import Layout from 'layout';
import Hero from 'components/404';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const NotFoundPage = ({ location }) => {
  useEffect(() => {
    trackCustomEvent({
      category: 'Error 404',
      action: document.location.href,
    });
  }, []);
  return (
    <Layout title="Page Not Found" location={location}>
      <Hero />
    </Layout>
  );
};

export default NotFoundPage;
