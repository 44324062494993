import React from 'react';

import { HOME_URL } from 'data/urls';
import Button from 'elements/Button';
import { Link } from 'gatsby';
import { HeroBase } from './styles';

export default function Hero() {
  return (
    <HeroBase>
      <img src="/images/misc/404.png" alt="not found" />
      <h3>The Page is Unknown</h3>
      <p>Sorry for that, but the page you are looking for doesn’t exist.</p>
      <Button primary as={Link} to={HOME_URL}>
        Go to home
      </Button>
    </HeroBase>
  );
}
