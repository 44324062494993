import styled from 'styled-components';
import Container from 'elements/Container';
import { flexCenter } from 'styles/mixins';
import { para } from 'styles/headings';
import { headMD } from 'styles/typography';

export const HeroBase = styled(Container)`
  ${flexCenter('column')}
  margin: 16vh auto;

  img {
    object-fit: contain;
    max-height: 280px;
    max-width: 240px;
    margin-bottom: 32px;
  }

  h3 {
    ${headMD};
  }

  p {
    ${para};
    font-size: 14px;
    margin-top: 8px;
  }

  a {
    margin-top: 40px;
    min-width: 180px;
    line-height: 1.5;
    padding: 8px 40px;
  }
`;
